import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Carousel, { Modal, ModalGateway } from 'react-images'

import Embed, { EmbedGrid } from './embed'

export const Gallery = ({ images }) => {
    const sources = images.map(({ publicURL }) => ({ src: publicURL }))
    const [isOpen, setIsOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    function toggleLightbox(event, index) {
        event.preventDefault()
        setIsOpen(!isOpen)
        setSelectedIndex(index)
    }

    return (
        <>
            <EmbedGrid>
                {images.map((photo, i) => (
                    <a
                        onClick={event => toggleLightbox(event, i)}
                        href={photo.publicURL}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={i}
                    >
                        <Image image={photo} shadow={false} />
                    </a>
                ))}
            </EmbedGrid>
            <ModalGateway>
                {isOpen ? (
                    <Modal onClose={() => setIsOpen(!isOpen)}>
                        <Carousel views={sources} currentIndex={selectedIndex} />
                    </Modal>
                ) : null}
            </ModalGateway>
        </>
    )
}

export const Image = ({ image, ...props }) => {
    return (
        <Embed {...props}>
            <Img fluid={image.childImageSharp.fluid} fixed={image.childImageSharp.fixed} />
        </Embed>
    )
}

Image.propTypes = {
    image: PropTypes.shape({
        childImageSharp: PropTypes.object,
        fluid: PropTypes.object,
    }).isRequired,
}

export default Image
