import React from 'react'
import { graphql } from 'gatsby'

import Link from '../../components/link'
import { H3, H4, H5, Small, Label } from '../../components/typography'
import Image, { Gallery } from '../../components/image'
import Embed from '../../components/embed'
import { Table, Tr, Td } from '../../components/table'
import { Section, Banner, Col } from '../../components/section'
import { Profile, ProfileGrid } from '../../components/profile'
import PatternElement from '../../components/pattern-element'
import Tickets from '../../components/tickets'
import Header from '../../components/header'
import Footer from '../../components/footer'
import SEO from '../../components/seo'

import '../../styles/index.sass'
import patternElement1 from '../../images/pattern-element1.svg'
import patternElement2 from '../../images/pattern-element2.svg'
import patternElement3 from '../../images/pattern-element3.svg'

const SOCIAL_TIME = 'Social time'
const SCHEDULE = [
    ['09:00', 'Check in & Registration'],
    ['10:00', 'Opening', 'Matthew Honnibal & Ines Montani', 'Explosion'],
    [
        '10:15',
        'Transfer Learning in Open-Source Natural Language Processing',
        'Sebastian Ruder',
        'DeepMind',
        'Keynote',
    ],
    [
        '10:50',
        '"So when will spaCy support BERT?" Improving sparse transformer models for efficient self-attention',
        'Giannis Daras',
        'Explosion',
    ],
    ['11:15', 'Applied NLP: Lessons from the Field', 'Peter Baumgartner', 'RTI International'],
    [
        '11:40',
        'Lessons learned in helping developers ship conversational AI assistants to production',
        'Justina Petraitytė',
        'Rasa',
    ],
    ['12:00', SOCIAL_TIME],
    ['13:00', 'The missing elements in NLP', 'Yoav Goldberg', 'Allen AI', 'Keynote'],
    [
        '13:35',
        'Entity linking functionality in spaCy: grounding textual mentions to knowledge base concepts',
        'Sofie Van Landeghem',
        'Explosion',
    ],
    ['14:00', 'Rethinking rule-based lemmatization', 'Guadalupe Romero', 'Explosion'],
    [
        '14:20',
        'ScispaCy: A full spaCy pipeline and models for scientific and biomedical text',
        'Mark Neumann',
        'Allen AI',
    ],
    ['14:40', SOCIAL_TIME],
    ['15:30', 'Financial NLP at S&P Global', 'Patrick Harrison', 'S&P Global'],
    ['15:55', 'NLP in Asset Management', 'McKenzie Marshall', 'Barings'],
    ['16:20', "spaCy in the News: Quartz's NLP pipeline", 'David Dodson', 'Quartz'],
    ['16:40', SOCIAL_TIME],
    [
        '17:00',
        'Closing: spaCy and Explosion, present and future',
        'Matthew Honnibal & Ines Montani',
        'Explosion',
    ],
    ['18:00', 'End'],
]

const TRAINING_SCHEDULE = [
    ['09:00', 'Check in & Registration'],
    ['09:30', 'Session 1'],
    ['13:00', 'Lunch break'],
    ['14:00', 'Session 2'],
    ['17:30', 'End'],
    ['19:30', 'Dinner at a local restaurant'],
]

const VIDEOS = [
    'hNPwRPg9BrQ',
    'KwKr_e7xBQ4',
    'QRGMJWwOU94',
    '1jI0mTcNRUU',
    'e12danHhlic',
    'PW3RJM8tDGo',
    '88zcQODyuko',
    '2_HSKDALwuw',
    'rdmaR4WRYEM',
    'kX14Ycieju8',
    'azrVX8JksMU',
    'Jk9y17lvltY',
]

const Index = ({ data }) => {
    const { eventbriteId, eventbriteUrl } = data.site.siteMetadata
    const photos = data.photos.edges.map(({ node }) => node)
    console.log(photos[0])
    return (
        <>
            <SEO />
            <Header>
                <H3 tilt="left" gutterBottom={false}>
                    July 5-6, 2019
                </H3>
                <br />
                <H3 tilt="right" gutterBottom={false}>
                    Berlin, Germany
                </H3>
            </Header>
            <Section id="speakers">
                <ProfileGrid>
                    <Profile
                        name="Yoav Goldberg"
                        title="Allen AI"
                        badge="Keynote"
                        image={data.yoav}
                    />
                    <Profile
                        name="Sebastian Ruder"
                        title="DeepMind"
                        badge="Keynote"
                        image={data.sebastian}
                    />
                    <Profile name="Matthew Honnibal" title="Explosion" image={data.matt} />
                    <Profile name="Ines Montani" title="Explosion" image={data.ines} />
                    <Profile name="Mark Neumann" title="Allen AI" image={data.mark} />
                    <Profile name="McKenzie Marshall" title="Barings" image={data.mckenzie} />
                    <Profile name="Sofie Van Landeghem" title="Explosion" image={data.sofie} />
                    <Profile name="Giannis Daras" title="Explosion" image={data.giannis} />
                    <Profile name="Guadalupe Romero" title="Explosion" image={data.guadi} />
                    <Profile name="Patrick Harrison" title="S&P Global" image={data.patrick} />
                    <Profile name="Justina Petraityte" title="Rasa" image={data.justina} />
                    <Profile
                        name="Peter Baumgartner"
                        title="RTI International"
                        image={data.peter}
                    />
                    <Profile name="David Dodson" title="Quartz" image={data.david} />
                </ProfileGrid>
            </Section>

            <PatternElement src={patternElement1} top={-80} left={50} />

            <Banner title="Photos" id="photos" theme="green" withPattern overlap>
                <p>
                    <strong>Thank you for making spaCy IRL such an amazing conference.</strong> We
                    still can't believe everything went so well. The positive vibe in the room was
                    incredible! Here are some visual impressions captured by our event photographer.
                    If you're in one of the photos, feel free to download and use it for your social
                    media profiles or elsewhere. You do not have to credit our photographer, but
                    he'd appreciate it. All photos by{' '}
                    <Link to="https://www.instagram.com/ema_discordant/">Ema Discordant</Link>.
                </p>

                <Gallery images={photos} />
            </Banner>

            <PatternElement src={patternElement2} top={-120} left={50} />

            <Banner title="Videos" id="videos" theme="blue" withPattern overlap grid>
                {VIDEOS.map(video => (
                    <Col>
                        <Embed video>
                            <iframe
                                title={video}
                                width="560"
                                height="315"
                                src={`https://www.youtube-nocookie.com/embed/${video}`}
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            />
                        </Embed>
                    </Col>
                ))}

                <Col full>
                    <p>
                        You can check out the full YouTube playlist{' '}
                        <Link to="https://www.youtube.com/playlist?list=PLBmcuObd5An4UC6jvK_-eSl6jCvP1gwXc">
                            here
                        </Link>
                        .
                    </p>
                </Col>
            </Banner>

            <Banner title="Conference" id="conference" theme="green" withPattern grid overlap>
                <Col>
                    <p>
                        We're pleased to invite the <Link to="https://spacy.io">spaCy</Link>{' '}
                        community and other folks working on Natural Language Processing to Berlin
                        this summer for a small and intimate event <strong>July 6, 2019</strong>.
                        We've booked a beautiful venue in one of Berlin's coolest neighborhoods,
                        hand-picked an awesome lineup of speakers and scheduled plenty of social
                        time to get to know each other and exchange ideas.
                    </p>
                    <p>
                        The conference will take place at Heimathafen Neukölln, with one track of
                        talks. Tickets include entry, coffee, tea and soft drinks, and snacks
                        throughout the day.{' '}
                        <strong>We're looking forward to seeing you there!</strong>
                    </p>

                    <p>
                        Coming to Berlin for spaCy IRL and want to make the most of the city? Check
                        out our <Link to="/2019/berlin-guide">Berlin Guide</Link>.
                    </p>
                </Col>

                <Image
                    image={data.locationConference}
                    tilt="left"
                    caption={
                        <Link to="https://www.google.com/search?q=heimathafen+neukölln" hidden>
                            <strong>Heimathafen Neukölln</strong> &middot; Karl-Marx-Straße 141
                            &middot; 12043 Berlin &middot; Germany
                        </Link>
                    }
                />

                <Col full>
                    <H3 id="schedule">Schedule</H3>
                    <Table>
                        {SCHEDULE.map(([time, title, speaker, role, label]) => (
                            <Tr key={time}>
                                <Td>
                                    <H5>{time}</H5>
                                </Td>
                                <Td>
                                    {label && <Label>{label}</Label>}
                                    <strong>{title}</strong>
                                    {speaker && (
                                        <>
                                            <br />
                                            <Small>
                                                {speaker} &middot; {role}
                                            </Small>
                                        </>
                                    )}
                                </Td>
                            </Tr>
                        ))}
                    </Table>
                </Col>
            </Banner>

            <PatternElement src={patternElement2} top={-120} left={50} />

            <Banner title="Corporate Training" id="training" theme="blue" grid withPattern>
                <p>
                    On <strong>July 4 and 5, 2019</strong>, the days before the conference, we'll
                    also be holding a training day for teams using spaCy in production. Over the
                    course of the day, you'll learn more about constructing custom end-to-end
                    pipelines, training more accurate models for specific domains, and scaling spaCy
                    for large text analytics workloads.
                    <br />
                    <br />
                    The training day will consist of{' '}
                    <strong>7 hours of hands-on instruction</strong> from Matthew Honnibal and Ines
                    Montani, spaCy's core developers.
                </p>

                <p>
                    The day will be aimed towards people already familiar with the library. This
                    isn't an introductory session where we rehash content you can find in the docs.
                    We want to do a deeper dive with a small number of people, to help you build
                    more effective NLP solutions. To keep things running smoothly and give us enough
                    hands-on time with all participants, the training day will be{' '}
                    <strong>strictly limited in size</strong>. So if you're interested, be sure to
                    book quickly!
                </p>

                <Col>
                    <H4>Location</H4>

                    <p>
                        The training will take place at{' '}
                        <Link to="https://goo.gl/maps/JuAHG3vrHqmKRDaV8">Mitosis Factory</Link>,
                        Sonnenallee 67, 12045 Berlin. (Note: They also have another location a few
                        blocks away, so make sure to double-check the address.) Drinks and lunch
                        will be provided on site.
                    </p>

                    <H4>What to bring</H4>

                    <p>
                        Laptop with working wifi card, Python environment with spaCy installed and
                        the models you want to work with downloaded, A/C adapter suitable for
                        European power sockets (the one with two circular prongs).
                        <br />
                        <br />
                        We will provide datasets for different domains and other resources to work
                        with, plus access to Prodigy for a little on-site annotation.
                    </p>
                </Col>

                <Col>
                    <H4>Schedule</H4>
                    <Table>
                        {TRAINING_SCHEDULE.map(([time, title]) => (
                            <Tr key={time}>
                                <Td>
                                    <H5>{time}</H5>
                                </Td>
                                <Td>
                                    <strong>{title}</strong>
                                </Td>
                            </Tr>
                        ))}
                    </Table>
                    <br />
                    <br />

                    <p>
                        <Small>
                            <strong>Dinner on July 4:</strong>{' '}
                            <Link to="https://goo.gl/maps/fiDVzrgryvkEKnjY6">BRLO Brwhouse</Link>{' '}
                            &middot; Schöneberger Straße 16 &middot; 10963 Berlin
                            <br />
                            <strong>Dinner on July 5:</strong>{' '}
                            <Link to="https://goo.gl/maps/KM1bgzE6kAFiLx2s8">Shishi</Link> &middot;
                            Ritterstraße 12-14 &middot; 10969 Berlin
                        </Small>
                    </p>
                </Col>

                <Image
                    image={data.locationTraining1}
                    tilt="left"
                    caption={
                        <Link to="https://mitosis.co/en/sonnenallee/" hidden>
                            <strong>Mitosis Factory</strong> &middot; Sonnenallee 67 &middot; 12045
                            Berlin
                        </Link>
                    }
                />
                <Image image={data.locationTraining2} tilt="right" lower />
            </Banner>

            <PatternElement src={patternElement3} top={-150} right={70} />

            <Section title="Tickets" id="tickets" withPattern>
                <p>
                    Tickets are available on <Link to={eventbriteUrl}>Eventbrite</Link>. A limited
                    number of Early Bird tickets are available at a discount, as early confirmations
                    will really help us finish organizing the event. In order to attend the event,
                    you must read and agree to the terms of the{' '}
                    <Link to="/2019/coc">Code of Conduct</Link>.
                </p>

                <p>
                    Due to popular demand, we've added a <strong>second day of training</strong> on{' '}
                    Thursday, <strong>July 4</strong>. The trainings will be very small (30-40
                    people), so be sure to book quickly.
                </p>

                <Tickets id={eventbriteId} url={eventbriteUrl} height={150} />

                <div>
                    <H3>Contact us</H3>

                    <p>
                        We're really looking forward to putting this on, so we hope we can see you
                        there. If you have any questions or comments, feel free to get in touch. You
                        can email us at{' '}
                        <Link to="mailto:contact@explosion.ai">contact@explosion.ai</Link>, or drop
                        us a quick line{' '}
                        <Link to="https://twitter.com/explosion_ai">on Twitter</Link>.
                    </p>
                </div>
            </Section>
            <Footer />
        </>
    )
}

export default Index

export const query = graphql`
    query {
        site {
            siteMetadata {
                eventbriteId
                eventbriteUrl
            }
        }
        photos: allFile(
            filter: { extension: { eq: "jpg" }, relativeDirectory: { eq: "photos" } }
            sort: { order: ASC, fields: [name] }
        ) {
            edges {
                node {
                    publicURL
                    ...eventImage
                }
            }
        }

        ines: file(relativePath: { eq: "speakers/ines-montani.jpg" }) {
            ...profileImage
        }
        matt: file(relativePath: { eq: "speakers/matthew-honnibal.jpg" }) {
            ...profileImage
        }
        guadi: file(relativePath: { eq: "speakers/guadalupe-romero.jpg" }) {
            ...profileImage
        }
        sofie: file(relativePath: { eq: "speakers/sofie-van-landeghem.jpg" }) {
            ...profileImage
        }
        sebastian: file(relativePath: { eq: "speakers/sebastian-ruder.jpg" }) {
            ...profileImage
        }
        mckenzie: file(relativePath: { eq: "speakers/mckenzie-marshall.jpg" }) {
            ...profileImage
        }
        yoav: file(relativePath: { eq: "speakers/yoav-goldberg.jpg" }) {
            ...profileImage
        }
        mark: file(relativePath: { eq: "speakers/mark-neumann.jpg" }) {
            ...profileImage
        }
        giannis: file(relativePath: { eq: "speakers/giannis-daras.jpg" }) {
            ...profileImage
        }
        justina: file(relativePath: { eq: "speakers/justina-petraityte.jpg" }) {
            ...profileImage
        }
        peter: file(relativePath: { eq: "speakers/peter-baumgartner.jpg" }) {
            ...profileImage
        }
        patrick: file(relativePath: { eq: "speakers/patrick-harrison.jpg" }) {
            ...profileImage
        }
        david: file(relativePath: { eq: "speakers/david-dodson.jpg" }) {
            ...profileImage
        }
        locationConference: file(relativePath: { eq: "location-conference1.jpg" }) {
            ...locationImage
        }
        locationTraining1: file(relativePath: { eq: "location-training1.jpg" }) {
            ...locationImage
        }
        locationTraining2: file(relativePath: { eq: "location-training2.jpg" }) {
            ...locationImage
        }
    }
`

export const profileImage = graphql`
    fragment profileImage on File {
        childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

export const locationImage = graphql`
    fragment locationImage on File {
        childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

export const eventImage = graphql`
    fragment eventImage on File {
        childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
                ...GatsbyImageSharpFixed
            }
            fixed(width: 100, height: 100, quality: 100) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`
