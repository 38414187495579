import React from 'react'
import PropTypes from 'prop-types'

import Link from './link'

import classes from '../styles/tickets.module.sass'

const Tickets = ({ id, url, badge, height = 1100 }) => (
    <div className={classes.root}>
        {badge && (
            <div className={classes.badge}>
                <Link to={url} hidden>
                    {badge}
                </Link>
            </div>
        )}
        <iframe
            title="Buy tickets on Eventbrite"
            src={`https://eventbrite.de/tickets-external?eid=${id}&ref=etckt`}
            frameBorder="0"
            height={height}
            width="100%"
            scrolling="auto"
            allowtransparency="true"
        />
    </div>
)

Tickets.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    badge: PropTypes.string,
}

export default Tickets
