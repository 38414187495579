import React from 'react'
import PropTypes from 'prop-types'

import classes from '../styles/pattern-element.module.sass'

const PatternElement = ({ src, top, left, right, width }) => (
    <div className={classes.root}>
        <img src={src} alt="" className={classes.img} style={{ top, left, right, width }} />
    </div>
)

PatternElement.propTypes = {
    src: PropTypes.string.isRequired,
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    width: PropTypes.number,
}

export default PatternElement
