import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import classes from '../styles/embed.module.sass'

export const EmbedGrid = ({ children }) => <div className={classes.grid}>{children}</div>

export const Embed = ({ children, tilt, lower, caption, video, shadow = true }) => {
    const rootClassNames = classNames(classes.root, {
        [classes.lower]: !!lower,
    })
    const tiltClassNames = classNames({
        [classes.tiltLeft]: tilt === 'left',
        [classes.tiltRight]: tilt === 'right',
    })
    const embedClassNames = classNames(classes.embed, tiltClassNames, {
        [classes.video]: !!video,
        [classes.shadow]: !!shadow,
    })
    const captionClassNames = classNames(classes.caption, tiltClassNames)
    return (
        <div className={rootClassNames}>
            <figure className={embedClassNames}>{children}</figure>
            {caption && <figcaption className={captionClassNames}>{caption}</figcaption>}
        </div>
    )
}

Embed.defaultProps = {
    tilt: null,
    lower: false,
}

Embed.propTypes = {
    tilt: PropTypes.oneOf(['left', 'right', null]),
    lower: PropTypes.bool,
    children: PropTypes.node.isRequired,
    caption: PropTypes.node,
}

export default Embed
