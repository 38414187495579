import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Img from 'gatsby-image'

import { H4 } from './typography'
import classes from '../styles/profile.module.sass'

export const ProfileGrid = ({ children }) => <div className={classes.grid}>{children}</div>

export const Profile = ({ name, title, badge, image }) => {
    const imageClassNames = classNames(classes.image, { [classes.imageEmpty]: !image })
    return (
        <div className={classes.root}>
            {badge && <div className={classes.badge}>{badge}</div>}
            <div className={imageClassNames}>
                {image && <Img fluid={image.childImageSharp.fluid} />}
            </div>
            <H4>{name}</H4>
            {title}
        </div>
    )
}

Profile.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    badge: PropTypes.string,
    image: PropTypes.shape({
        childImageSharp: PropTypes.object,
        fluid: PropTypes.object,
    }),
}
