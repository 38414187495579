import React from 'react'

import classes from '../styles/table.module.sass'

export const Tr = props => <tr className={classes.tr} {...props} />
export const Td = props => <td className={classes.td} {...props} />

export const Table = ({ children }) => (
    <table className={classes.table}>
        <tbody>{children}</tbody>
    </table>
)
